<template>
  <div class="content-categorys">
    <h2 class="w-100 text-center mb-4">Categorías</h2>
    <div id="container-categorys-home" class="container-box-category">
      <router-link
        :to="{
          name: 'StoreCollection',
          params: {
            collection: dataCategorys[0].slug,
            nameCreative: `banner categorys home ${dataCategorys[0].descripcion}`,
            slot: `banner-categorys-home`,
            id: `banner-categorys-home-${dataCategorys[0].slug}`,
            name: `${dataCategorys[0].descripcion}`,
          },
        }"
        class="category-card category-card-left"
      >
        <div class="content-category" style="left: 0.5rem">
          <h3>
            {{
              dataCategorys[0].descripcion
                .toLowerCase()
                .replace(/^\w/, d => d.toUpperCase())
            }}
          </h3>
          <p>{{ dataCategorys[0].cantidad }} productos</p>
        </div>
        <img class="pe-md-2" :src="dataCategorys[0].img" />
      </router-link>
    </div>
    <div
      class="container-box-category d-flex flex-wrap align-items-between"
      style="aspect-ratio: 31/30"
    >
      <router-link
        :to="{
          name: 'StoreCollection',
          params: {
            collection: dataCategorys[1].slug,
            nameCreative: `banner categorys home ${dataCategorys[1].descripcion}`,
            slot: `banner-categorys-home`,
            id: `banner-categorys-home-${dataCategorys[1].slug}`,
            name: `${dataCategorys[1].descripcion}`,
          },
        }"
        class="category-card category-card-middle mb-4 mb-md-auto"
      >
        <div class="content-category">
          <h3>
            {{
              dataCategorys[1].descripcion
                .toLowerCase()
                .replace(/^\w/, d => d.toUpperCase())
            }}
          </h3>
          <p>{{ dataCategorys[1].cantidad }} productos</p>
        </div>
        <img class="px-md-2" :src="dataCategorys[1].img" />
      </router-link>
      <router-link
        :to="{
          name: 'StoreCollection',
          params: {
            collection: dataCategorys[2].slug,
            nameCreative: `banner categorys home ${dataCategorys[2].descripcion}`,
            slot: `banner-categorys-home`,
            id: `banner-categorys-home-${dataCategorys[2].slug}`,
            name: `${dataCategorys[2].descripcion}`,
          },
        }"
        class="category-card category-card-middle mt-auto"
      >
        <div class="content-category">
          <h3>
            {{
              dataCategorys[2].descripcion
                .toLowerCase()
                .replace(/^\w/, d => d.toUpperCase())
            }}
          </h3>
          <p>{{ dataCategorys[2].cantidad }} productos</p>
        </div>
        <img class="px-md-2" :src="dataCategorys[2].img" />
      </router-link>
    </div>
    <div class="container-box-category">
      <router-link
        :to="{
          name: 'StoreCollection',
          params: {
            collection: dataCategorys[3].slug,
            nameCreative: `banner categorys home ${dataCategorys[3].descripcion}`,
            slot: `banner-categorys-home`,
            id: `banner-categorys-home-${dataCategorys[3].slug}`,
            name: `${dataCategorys[3].descripcion}`,
          },
        }"
        class="category-card category-card-right"
      >
        <div class="content-category">
          <h3>
            {{
              dataCategorys[3].descripcion
                .toLowerCase()
                .replace(/^\w/, d => d.toUpperCase())
            }}
          </h3>
          <p>{{ dataCategorys[3].cantidad }} productos</p>
        </div>
        <img class="ps-md-2" :src="dataCategorys[3].img" />
      </router-link>
    </div>
  </div>
</template>
<script>
import { GA4ViewPromotion } from '@/GA4/events'
export default {
  props: {
    dataCategorys: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      viewCategorys: false,
    }
  },
  mounted() {
    setTimeout(() => {
      this.validateViewComponent()
    }, 500)
    window.addEventListener('scroll', this.validateViewComponent)
  },
  methods: {
    validateViewComponent() {
      let vitrina = document.getElementById('container-categorys-home')
      if (
        vitrina &&
        !this.viewCategorys &&
        vitrina.getBoundingClientRect().top -
          (window.innerHeight - vitrina.offsetHeight / 2) <=
          0
      ) {
        this.viewCategorys = true
        this.dataCategorys.forEach(element => {
          GA4ViewPromotion({
            nameCreative: `banner categorys home ${element.descripcion}`,
            slot: `banner-categorys-home`,
            id: `banner-categorys-home-${element.slug}`,
            name: `${element.descripcion}`,
          })
        })
        window.removeEventListener('scroll', this.validateViewComponent)
      }
    },
  },
}
</script>
<style lang="scss">
.content-categorys {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 3rem;
  margin-bottom: 4rem;
  width: 80%;
  margin-right: auto;
  margin-left: auto;
  .container-box-category {
    width: 33.33%;
    @media (max-width: 991px) {
      width: 50% !important;
      margin-bottom: 2rem;
    }
    @media (max-width: 768px) {
      width: 100% !important;
      margin-bottom: 1.5rem;
    }
  }
  .category-card {
    width: 100%;
    height: 100%;
    display: flex;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    // aspect-ratio: 28/31;
    img {
      width: 100%;
    }
    .content-category {
      position: absolute;
      left: 1rem;
      bottom: 0.5rem;
      z-index: 2;
      color: black;
      transition: all 0.2s;
      h3 {
        font-size: 24px;
        margin-bottom: 0;
        // text-shadow: 1px 1px 16px #696969;
      }
      p {
        margin-bottom: 0;
        font-size: 13px;
        // text-shadow: 1px 1px 16px #696969;
      }
    }
    &::after {
      content: '';
      position: absolute;
      top: 100%;
      // top: calc(100% - 66px);
      height: 100%;
      background-color: #00000040;
      // background-color: #00000070;
      z-index: 1;
      transition: all 0.3s linear;
    }
    &:hover {
      &::after {
        // background-color: #00000070;
        top: 0;
        // top: calc(100% - 66px) !important;
      }
      .content-category {
        color: white;
      }
    }
    &-left {
      &::after {
        left: 0;
        width: calc(100% - 0.5rem);
      }
    }
    &-middle {
      max-height: calc(50% - 0.5rem);
      &::after {
        left: 0.5rem;
        width: calc(100% - 1rem);
        transition: all 0.2s linear;
      }
    }
    &-right {
      &::after {
        left: 0.5rem;
        width: calc(100% - 0.5rem);
      }
    }
    @media (max-width: 768px) {
      &::after {
        width: 100%;
        left: 0;
      }
    }
  }
  @media (max-width: 768px) {
    width: 90%;
  }
}
</style>
