var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content-categorys"},[_c('h2',{staticClass:"w-100 text-center mb-4"},[_vm._v("Categorías")]),_c('div',{staticClass:"container-box-category",attrs:{"id":"container-categorys-home"}},[_c('router-link',{staticClass:"category-card category-card-left",attrs:{"to":{
        name: 'StoreCollection',
        params: {
          collection: _vm.dataCategorys[0].slug,
          nameCreative: `banner categorys home ${_vm.dataCategorys[0].descripcion}`,
          slot: `banner-categorys-home`,
          id: `banner-categorys-home-${_vm.dataCategorys[0].slug}`,
          name: `${_vm.dataCategorys[0].descripcion}`,
        },
      }}},[_c('div',{staticClass:"content-category",staticStyle:{"left":"0.5rem"}},[_c('h3',[_vm._v(" "+_vm._s(_vm.dataCategorys[0].descripcion .toLowerCase() .replace(/^\w/, d => d.toUpperCase()))+" ")]),_c('p',[_vm._v(_vm._s(_vm.dataCategorys[0].cantidad)+" productos")])]),_c('img',{staticClass:"pe-md-2",attrs:{"src":_vm.dataCategorys[0].img}})])],1),_c('div',{staticClass:"container-box-category d-flex flex-wrap align-items-between",staticStyle:{"aspect-ratio":"31/30"}},[_c('router-link',{staticClass:"category-card category-card-middle mb-4 mb-md-auto",attrs:{"to":{
        name: 'StoreCollection',
        params: {
          collection: _vm.dataCategorys[1].slug,
          nameCreative: `banner categorys home ${_vm.dataCategorys[1].descripcion}`,
          slot: `banner-categorys-home`,
          id: `banner-categorys-home-${_vm.dataCategorys[1].slug}`,
          name: `${_vm.dataCategorys[1].descripcion}`,
        },
      }}},[_c('div',{staticClass:"content-category"},[_c('h3',[_vm._v(" "+_vm._s(_vm.dataCategorys[1].descripcion .toLowerCase() .replace(/^\w/, d => d.toUpperCase()))+" ")]),_c('p',[_vm._v(_vm._s(_vm.dataCategorys[1].cantidad)+" productos")])]),_c('img',{staticClass:"px-md-2",attrs:{"src":_vm.dataCategorys[1].img}})]),_c('router-link',{staticClass:"category-card category-card-middle mt-auto",attrs:{"to":{
        name: 'StoreCollection',
        params: {
          collection: _vm.dataCategorys[2].slug,
          nameCreative: `banner categorys home ${_vm.dataCategorys[2].descripcion}`,
          slot: `banner-categorys-home`,
          id: `banner-categorys-home-${_vm.dataCategorys[2].slug}`,
          name: `${_vm.dataCategorys[2].descripcion}`,
        },
      }}},[_c('div',{staticClass:"content-category"},[_c('h3',[_vm._v(" "+_vm._s(_vm.dataCategorys[2].descripcion .toLowerCase() .replace(/^\w/, d => d.toUpperCase()))+" ")]),_c('p',[_vm._v(_vm._s(_vm.dataCategorys[2].cantidad)+" productos")])]),_c('img',{staticClass:"px-md-2",attrs:{"src":_vm.dataCategorys[2].img}})])],1),_c('div',{staticClass:"container-box-category"},[_c('router-link',{staticClass:"category-card category-card-right",attrs:{"to":{
        name: 'StoreCollection',
        params: {
          collection: _vm.dataCategorys[3].slug,
          nameCreative: `banner categorys home ${_vm.dataCategorys[3].descripcion}`,
          slot: `banner-categorys-home`,
          id: `banner-categorys-home-${_vm.dataCategorys[3].slug}`,
          name: `${_vm.dataCategorys[3].descripcion}`,
        },
      }}},[_c('div',{staticClass:"content-category"},[_c('h3',[_vm._v(" "+_vm._s(_vm.dataCategorys[3].descripcion .toLowerCase() .replace(/^\w/, d => d.toUpperCase()))+" ")]),_c('p',[_vm._v(_vm._s(_vm.dataCategorys[3].cantidad)+" productos")])]),_c('img',{staticClass:"ps-md-2",attrs:{"src":_vm.dataCategorys[3].img}})])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }