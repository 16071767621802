<template>
  <div class="content-404">
    <div class="content-card">
      <img src="@/assets/icons/page404.svg" alt="page 404" />
      <h2>Parece que esta página no existe</h2>
      <p>
        La página que intentas buscar no existe, intenta realizar una nueva
        búsqueda o navega entre nuestras categorías.
      </p>
    </div>
    <categorys v-if="dataHome" :data-categorys="dataHome.categorias" />
  </div>
</template>
<script>
import Categorys from '@/components/home/Categorys'
import { mapActions, mapState } from 'vuex'
export default {
  components: {
    Categorys,
  },
  computed: {
    ...mapState('layouts', ['dataHome']),
  },
  mounted() {
    if (!this.dataHome) {
      this.getHome()
      this.getTopbar()
    }
    document.title = 'Page 404 | Clover Tienda de bolsos'
  },
  methods: {
    ...mapActions('layouts', ['getHome', 'getTopbar']),
  },
}
</script>
<style lang="scss">
.content-404 {
  padding: 2rem;
  .content-card {
    background-color: #ece4e9;
    width: 90%;
    padding: 4rem 2rem;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    border-radius: 50px;
    img {
      width: 100%;
      max-width: 450px;
    }
    h2 {
      font-size: 50px;
      width: 100%;
      text-align: center;
      color: #937788;
      margin-bottom: 3rem;
    }
    p {
      max-width: 550px;
      text-align: center;
      font-size: 18px;
      color: #937788;
    }
  }
  @media (max-width: 991px) {
    padding: 2rem 1.5rem;
    .content-card {
      width: 100%;
      padding: 4rem 1rem;
      h2 {
        font-size: 40px;
      }
    }
  }
}
</style>
